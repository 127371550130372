// order is important here
export const ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MANAGER: 'manager',
  ADVANCED_MANAGER: 'advanced_manager',
  EMPLOYEE: 'employee',
  REPORTING: 'reporting',
};

export const MEMBER_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  REVOKED: 'revoked',
  DRAFT: 'draft',
  INVITABLE: 'invitable',
  IGNORED: 'ignored',
  DELETED: 'deleted',
  INVITED: 'invited',
};

export const KYC_STATUS = {
  PENDING: 'pending',
  REVIEWABLE: 'reviewable',
  SUBMITTED: 'submitted',
  ACCEPTED: 'accepted',
  REFUSED: 'refused',
  WAITING_DOCUMENT: 'waiting_document',
  SUBMISSION_ERROR: 'submission_error',
  KYC_NOT_APPLICABLE: 'kyc_not_applicable',
  PROCESSING: 'processing',
};

export const DEFAULT_CUSTOM_PERMISSIONS_TEAM_OLD = Object.freeze({
  create_cards_and_review_request_cards: true,
  create_transfers_and_review_request_transfers: false,
  access_direct_debits: false,
  access_checks: false,
  manage_memberships: false,
  manage_bookkeepings: false,
  manage_supplier_invoices: false,
});

export const DEFAULT_CUSTOM_PERMISSIONS_TEAM = Object.freeze({
  create_cards: true,
  review_request_cards: true,
  create_transfers: false,
  access_direct_debits: false,
  access_checks: false,
  manage_memberships: false,
  manage_bookkeepings: false,
  manage_supplier_invoices: false,
});

export const DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION_OLD = Object.freeze({
  create_cards_and_review_request_cards: true,
  create_transfers_and_review_request_transfers: false,
  access_direct_debits: false,
  access_checks: false,
  manage_teams: false,
  access_bank_accounts_statements: false,
  manage_bookkeepings: false,
  manage_supplier_invoices: false,
});

export const DEFAULT_CUSTOM_PERMISSIONS_ORGANIZATION = Object.freeze({
  create_cards: true,
  review_request_cards: true,
  create_transfers: false,
  access_direct_debits: false,
  access_checks: false,
  manage_teams: false,
  access_bank_accounts_statements: false,
  manage_bookkeepings: false,
  manage_supplier_invoices: false,
});

export const INVITATION_TYPES = {
  STAFF: 'staff', // ADMIN, MANAGER, EMPLOYEE
  GUEST: 'guest', // REPORTING
};

export const INVITE_DISCOVERABILITY_STORAGE_KEY = 'invite-discoverability-viewed';
