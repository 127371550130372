import { htmlSafe } from '@ember/template';

import { BADGE_TYPE } from '@qonto/ui-kit/addon/constants/badge-types';

import { CODES, LAYOUT } from 'qonto/constants/empty-states/system';
import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';

const INFORM_LOTTIE_SRC = '/lotties/suppliers/empty-state-inform.json';
const NO_RESULTS_LOTTIE_SRC = '/lotties/empty-state/search-no-result.json';

export const getEmptyStateConfig = (intl, { onClick }) => {
  let dummyData = getDummyData(intl);

  return {
    name: 'suppliers',
    featureName: 'supplierInvoices',
    permissionKey: 'supplierInvoices',
    isNewLogic: true,

    variations: {
      ES_A3: {
        code: CODES.ES_A3,
        layout: LAYOUT.DISCOVER_PREVIEW,
        dummyData,
        variations: [
          {
            output: {
              badgeType: BADGE_TYPE.ACTIVATE,
              badgeText: intl.t('empty-states.system.activate.a3.badge'),
              title: intl.t('supplier-list.section.empty-state.a3.transfer.title'),
              subtitle: htmlSafe(intl.t('supplier-list.section.empty-state.a3.subtitle')),
              ctaText: intl.t('supplier-invoices.supplier-creation.title'),
              onClick,
            },
          },
        ],
      },
      ES_I1: {
        code: CODES.ES_I1,
        layout: LAYOUT.INFORM,
        lottieSrc: INFORM_LOTTIE_SRC,
        variations: [
          {
            inputs: {
              customInputs: {
                tab: SUPPLIER_STATUSES.UNARCHIVED,
              },
            },
            output: {
              title: intl.t('supplier-list.section.empty-state.no-active-supplier.title'),
              subtitle: intl.t('supplier-list.section.empty-state.no-active-supplier.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: SUPPLIER_STATUSES.ARCHIVED,
              },
            },
            output: {
              title: intl.t('supplier-list.section.empty-state.no-archived-supplier.title'),
              subtitle: intl.t('supplier-list.section.empty-state.no-archived-supplier.subtitle'),
            },
          },
        ],
      },
      ES_I2: {
        code: CODES.ES_I2,
        layout: LAYOUT.INFORM,
        lottieSrc: NO_RESULTS_LOTTIE_SRC,
        variations: [
          {
            inputs: {
              customInputs: {
                tab: SUPPLIER_STATUSES.UNARCHIVED,
              },
            },
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
          {
            inputs: {
              customInputs: {
                tab: SUPPLIER_STATUSES.ARCHIVED,
              },
            },
            output: {
              title: intl.t('empty-states.system.inform.i2.title'),
              subtitle: intl.t('empty-states.system.inform.i2.subtitle'),
            },
          },
        ],
      },
    },
  };
};

export const getDummyData = intl => {
  return {
    status: SUPPLIER_STATUSES.UNARCHIVED,
    items: [
      // 1st Row
      {
        name: intl.t('supplier-list.section.empty-state.a3.dummy-data.name-1'),
        email: intl.t('supplier-list.section.empty-state.a3.dummy-data.email-address-1'),
        outstandingBalance: {
          value: '366.00',
          currency: 'EUR',
        },
      },
      // 2nd Row
      {
        name: intl.t('supplier-list.section.empty-state.a3.dummy-data.name-2'),
        email: intl.t('supplier-list.section.empty-state.a3.dummy-data.email-address-2'),
        outstandingBalance: {
          value: '52.20',
          currency: 'EUR',
        },
      },
      // 3rd Row
      {
        name: intl.t('supplier-list.section.empty-state.a3.dummy-data.name-3'),
        email: intl.t('supplier-list.section.empty-state.a3.dummy-data.email-address-3'),
        outstandingBalance: {
          value: '1048.10',
          currency: 'EUR',
        },
      },
    ],
  };
};
