import { BADGE_TYPE } from '@qonto/ui-kit/constants/badge-types';

/**
 * Defines the structure for the `transfers` route configuration.
 * `transfers` key must match the id of the menu item in dual-nav.
 * @typedef {Object} TransfersConfig
 * @property {Object} sepa_out - Configuration for `sepa_out` feature.
 *                               Key should match the feature name inside `/v3/promotion_recommendation` endpoint.
 */

/**
 * Retrieves configuration settings for promotion recommendations.
 * This function provides a configuration setup for `promotionRecommendation` component.
 * @returns {TransfersConfig} An object containing the configuration settings for a route. Example, `transfers`.
 * @example
 * const config = getPromotionRecommendationsConfig();
 */
export const getPromotionRecommendationsConfig = (
  intl,
  isModularPricing = false // TODO modularPricing cleanup
) => {
  let PROMOTION_RECOMMENDATIONS_CONFIG = {
    transfers: {
      sepa_out: {
        [PROMOTION_TYPES.TRY]: {
          badgeType: BADGE_TYPE.TRY_FOR_FREE,
          badgeText: intl.t('promotions.system.promotional-card.free-trial.badge'),
          subtitle: intl.t('transfers.promotions.promotional-card.free-trial.body'),
          ctaText: intl.t('promotions.system.promotional-card.free-trial.cta'),
          tracking: {
            featureName: 'transfers',
          },
        },
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('transfers.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('promotions.system.promotional-card.spend-smart.cta'),
          tracking: {
            featureName: 'transfers',
          },
        },
      },
    },
    cards: {
      physical_cards: {
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('physical-cards.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('promotions.system.promotional-card.spend-smart.cta'),
          tracking: {
            featureName: 'cards',
            upsell_type: 'one_card',
          },
        },
      },
      virtual_cards: {
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('virtual-cards.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('upsell.add-ons.cta'),
          tracking: {
            featureName: 'cards',
            upsell_type: 'virtual_card',
          },
        },
        [PROMOTION_TYPES.TRY]: {
          badgeType: BADGE_TYPE.TRY_FOR_FREE,
          badgeText: intl.t('promotions.system.promotional-card.free-trial.badge'),
          subtitle: intl.t('virtual-cards.promotions.promotional-card.free-trial.body'),
          ctaText: intl.t('promotions.system.promotional-card.free-trial.cta'),
          tracking: {
            featureName: 'cards',
            upsell_type: 'virtual_card',
          },
        },
      },
      advertising_card: {
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('online-advertising-cards.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('upsell.add-ons.cta'),
          tracking: {
            featureName: 'cards',
            upsell_type: 'advertising_card',
          },
        },
        [PROMOTION_TYPES.TRY]: {
          badgeType: BADGE_TYPE.TRY_FOR_FREE,
          badgeText: intl.t('promotions.system.promotional-card.free-trial.badge'),
          subtitle: intl.t('online-advertising-cards.promotions.promotional-card.free-trial.body'),
          ctaText: intl.t('promotions.system.promotional-card.free-trial.cta'),
          tracking: {
            featureName: 'cards',
            upsell_type: 'advertising_card',
          },
        },
      },
    },
    'outgoing-direct-debits': {
      sepa_out: {
        [PROMOTION_TYPES.TRY]: {
          badgeType: BADGE_TYPE.TRY_FOR_FREE,
          badgeText: intl.t('promotions.system.promotional-card.free-trial.badge'),
          subtitle: intl.t('outgoing-direct-debits.promotions.promotional-card.free-trial.body'),
          ctaText: intl.t('promotions.system.promotional-card.free-trial.cta'),
          tracking: {
            featureName: 'outgoing-direct-debits',
          },
        },
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('outgoing-direct-debits.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('promotions.system.promotional-card.spend-smart.cta'),
          tracking: {
            featureName: 'outgoing-direct-debits',
          },
        },
      },
    },
    pagopa: {
      pagopa: {
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('pago-pa.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('promotions.system.promotional-card.spend-smart.cta'),
          tracking: {
            featureName: 'pagopa',
          },
        },
        [PROMOTION_TYPES.TRY]: {
          badgeType: BADGE_TYPE.TRY_FOR_FREE,
          badgeText: intl.t('promotions.system.promotional-card.free-trial.badge'),
          subtitle: intl.t('pago-pa.promotions.promotional-card.free-trial.body'),
          ctaText: intl.t('promotions.system.promotional-card.free-trial.cta'),
          tracking: {
            featureName: 'pagopa',
          },
        },
      },
    },
    checks: {
      checks: {
        [PROMOTION_TYPES.TRY]: {
          badgeType: BADGE_TYPE.TRY_FOR_FREE,
          badgeText: intl.t('promotions.system.promotional-card.free-trial.badge'),
          subtitle: intl.t('check-deposits.promotions.promotional-card.free-trial.body'),
          ctaText: intl.t('promotions.system.promotional-card.free-trial.cta'),
          tracking: {
            featureName: 'checks',
          },
        },
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('check-deposits.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('promotions.system.promotional-card.spend-smart.cta'),
          tracking: {
            featureName: 'checks',
          },
        },
      },
    },
    'user-access': {
      users: {
        [PROMOTION_TYPES.UPSELL]: {
          badgeType: BADGE_TYPE.SAVE,
          badgeText: intl.t('promotions.system.promotional-card.spend-smart.badge'),
          subtitle: intl.t('user-access.promotions.promotional-card.spend-smart.body'),
          ctaText: intl.t('promotions.system.promotional-card.spend-smart.cta'),
          tracking: {
            featureName: 'user-access',
          },
        },
      },
    },
  };

  // TODO modularPricing cleanup
  if (!isModularPricing) {
    delete PROMOTION_RECOMMENDATIONS_CONFIG.cards.advertising_card;

    PROMOTION_RECOMMENDATIONS_CONFIG.cards.virtual_cards[PROMOTION_TYPES.UPSELL] = {
      ...PROMOTION_RECOMMENDATIONS_CONFIG.cards.virtual_cards[PROMOTION_TYPES.UPSELL],
      subtitle: intl.t('virtual-cards.promotions.promotional-card.spend-smart.body-old'),
      ctaText: intl.t('promotions.system.promotional-card.spend-smart.cta'),
    };
  }

  return PROMOTION_RECOMMENDATIONS_CONFIG;
};

export const PROMOTION_TYPES = {
  TRY: 'try',
  UPSELL: 'upsell',
};
