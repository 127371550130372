export const CURRENCIES = {
  AED: 'AED',
  AUD: 'AUD',
  BHD: 'BHD',
  CAD: 'CAD',
  CHF: 'CHF',
  CLP: 'CLP',
  CRC: 'CRC',
  EUR: 'EUR',
  GBP: 'GBP',
  HKD: 'HKD',
  HUF: 'HUF',
  IDR: 'IDR',
  ILS: 'ILS',
  INR: 'INR',
  JPY: 'JPY',
  KES: 'KES',
  KRW: 'KRW',
  MAD: 'MAD',
  MXN: 'MXN',
  MYR: 'MYR',
  SGD: 'SGD',
  THB: 'THB',
  TRY: 'TRY',
  USD: 'USD',
  UYU: 'UYU',
  ZAR: 'ZAR',
};

export const NEW_TARGET_CURRENCIES = [CURRENCIES.EUR];
