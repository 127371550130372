import { isTesting, macroCondition } from '@embroider/macros';

export const INVOICE_STATUSES = {
  toReview: 'to_review',
  toPay: 'to_pay',
  pending: 'pending',
  scheduled: 'scheduled',
  paid: 'paid',
  inbox: 'inbox',
  archived: 'archived',
};

export const EDITABLE_DESCRIPTION_INVOICE_STATUSES = [
  INVOICE_STATUSES.toReview,
  INVOICE_STATUSES.toPay,
  INVOICE_STATUSES.paid,
];

export const INVOICE_SOURCES = {
  emailForward: 'email_forward',
  payByInvoice: 'pay_by_invoice',
  eInvoicing: 'e_invoicing',
  supplierInvoices: 'supplier_invoices',
};

export const DUE_FILTER_EVENT_MAP = {
  past_and_today: 'due',
  future: 'upcoming',
  missing_date: 'missing',
};

export const STATUS_COLORS = {
  inbox: 'gray',
  toPay: 'purple',
  toReview: 'gray',
  pending: 'orange',
  scheduled: 'purple',
  paid: 'green',
  archived: 'gray',
};

export const BASE_FLOW_STATUSES = [
  INVOICE_STATUSES.inbox,
  INVOICE_STATUSES.scheduled,
  INVOICE_STATUSES.paid,
];

export const ALL_STATUS = [
  INVOICE_STATUSES.inbox,
  INVOICE_STATUSES.pending,
  INVOICE_STATUSES.scheduled,
  INVOICE_STATUSES.paid,
];

export const INSIGHT_LABELS = {
  inboxAmount: 'inbox',
  scheduledAmount: 'scheduled',
  paidAmount: 'paid_last_30_days',
  pendingAmount: 'pending',
  inboxCounter: 'inbox_status_counter',
  pendingCounter: 'pending_status_counter',
  scheduledCounter: 'scheduled_status_counter',
  paidCounter: 'paid_status_counter',
  missingAmountsCounter: 'missing_amounts_counter',
  fxInvoicesCounter: 'fx_invoices_counter',
  totalDueAmount: 'total_due_amount',
  totalDueMissingAmmountCounter: 'total_due_missing_amount_counter',
  totalDueCounter: 'total_due_counter',
  totalDueFxCounter: 'total_due_fx_counter',
  totalOverdueCounter: 'total_overdue_counter',
  totalUpcomingAmount: 'total_upcoming_amount_next_90_days',
  totalUpcomingCounter: 'total_upcoming_counter_next_90_days',
  totalUpcomingFxCounter: 'total_upcoming_fx_counter_next_90_days',
  totalUpcomingMissingAmountCounter: 'total_upcoming_missing_amount_counter_next_90_days',
};

export const COCKPIT_INSIGHTS = 'cockpit-insights';

export const GENERIC_IBAN = 'FR14 2004 1010 0505 0001 3M02 606';

export const WAIT_FOR_OCR_SCAN = macroCondition(isTesting()) ? 500 : 6000;
export const ANIMATION_IN_DURATION_MS = macroCondition(isTesting()) ? 500 : 4000;
export const INSIGHTS_ANIMATION_DURATION = macroCondition(isTesting()) ? 1 : 800;
export const TRANSACTION_UPDATE_DELAY_MS = macroCondition(isTesting()) ? 0 : 3000;

export const SOURCE_TYPES = {
  EMAIL: 'email_forward',
  PAY_BY_INVOICE: 'pay_by_invoice',
  E_INVOICING: 'e_invoicing',
  SUPPLIER_INVOICES: 'supplier_invoices',
};

export const BASE_INSIGHTS = [INSIGHT_LABELS.totalDueAmount, INSIGHT_LABELS.totalUpcomingAmount];

export const INSIGHTS_PREFERENCE_STORAGE_KEY = 'supplier_invoices_insights_preference';

export const DUE_DATE_FILTER = {
  FUTURE: 'future',
  PAST_AND_TODAY: 'past_and_today',
  MISSING_DATE: 'missing_date',
};

export const EXPORT_FORMATS = {
  PDF: 'pdf',
  FATTURAPA: 'fatturapa',
};

export const EXPORT_ERROR_TYPES = {
  LIMIT_REACHED: 'limit_reached',
  NO_INVOICES: 'no_invoices_to_export',
  GENERIC_ERROR: 'generic_error',
};

export const FIRST_EINVOICE_TOOLTIP_DISMISSED_LOCAL_STORAGE_KEY =
  'fr-first-supplier-einvoice-instructional-tooltip-dismissed';

export const FRENCH_EINVOICING_CONSENT_STORAGE_KEY =
  'french-einvoicing-consent-product-discovery-card-dismissed';

export const FRENCH_EINVOICE_PAYMENT_STORAGE_KEY =
  'french-einvoice-payment-product-discovery-card-dismissed';
