export const HOTKEYS = {
  K: 'quick_actions',
  U: 'supplier_invoices',
  I: 'prompt',
};

export function modifierKey(isMac) {
  if (isMac) {
    return '⌘';
  }

  return 'Ctrl';
}

export function initializeQuickActions(owner) {
  let router = owner.lookup('service:router');
  let flowLinkManager = owner.lookup('service:flow-link-manager');
  let intl = owner.lookup('service:intl');
  let qontoPilotService = owner.lookup('service:qonto-pilot-service');

  let quickActions = {
    [intl.t('qonto-pilot.modal.quick-action.group.recent')]: [],
    [intl.t('qonto-pilot.modal.quick-action.group.tasks')]: [
      {
        key: 'task-sepa',
        isEnabled: true,
        isBeta: true,
        copy: intl.t('qonto-pilot.modal.quick-action.sepa'),
        hotkey: 'I',
        illustration: 'icon_nav_transfer',
        action: () => {},
      },
      {
        key: 'task-receipt',
        isEnabled: true,
        hotkey: 'U',
        copy: intl.t('qonto-pilot.modal.quick-action.receipt'),
        illustration: 'icon_upload_outlined',
        action: () => {},
      },
    ],
    [intl.t('qonto-pilot.modal.quick-action.group.shortcuts')]: [
      {
        key: 'shortcut-transfer',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.make-transfer'),
        illustration: 'icon_shortcut_create_transfer',
        action: () => {
          router.transitionTo('transfers.landing');
        },
      },
      {
        key: 'shortcut-virtual-card',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.create-virtual-card'),
        illustration: 'icon_upload_outlined',
        action: () => {
          flowLinkManager.transitionTo({
            name: 'card-new-virtual',
            stepId: 'bank-account',
          });
        },
      },
      {
        key: 'shortcut-create-invoice',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.create-invoice'),
        illustration: 'icon_shortcut_create_invoice',
        action: () => {
          router.transitionTo('receivable-invoices.new');
        },
      },
      {
        key: 'shortcut-direct-debit',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.direct-debit'),
        illustration: 'icon_nav_transaction_unselected',
        action: () => {
          flowLinkManager.transitionTo({ name: 'mandate', stepId: 'landing' });
        },
      },
      {
        key: 'shortcut-expense-reimbursement',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.reimbursement'),
        illustration: 'icon_shortcut_reimbursed',
        action: () => {
          router.transitionTo('requests.landing');
        },
      },
      {
        key: 'shortcut-add-account',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.account'),
        illustration: 'icon_user_plus_outlined',
        action: () => {
          router.transitionTo('accounts.new');
        },
      },
      {
        key: 'shortcut-add-client',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.client'),
        illustration: 'icon_user_plus_outlined',
        action: () => {
          router.transitionTo('clients.new');
        },
      },
      {
        key: 'shortcut-apply-financing',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.financing'),
        illustration: 'icon_nav_financing',
        action: () => {
          router.transitionTo('financing.partners');
        },
      },
      {
        key: 'shortcut-invite-employee',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.member'),
        illustration: 'icon_user_plus_outlined',
        action: () => {
          flowLinkManager.transitionTo({
            name: 'member-invite',
            stepId: 'personal-info',
            queryParams: { role: 'employee' },
          });
        },
      },
      {
        key: 'shortcut-invite-manager',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.manager'),
        illustration: 'icon_user_plus_outlined',
        action: () => {
          flowLinkManager.transitionTo({
            name: 'member-invite',
            stepId: 'personal-info',
            queryParams: { role: 'manager' },
          });
        },
      },
      {
        key: 'shortcut-invite-admin',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.admin'),
        illustration: 'icon_user_plus_outlined',
        action: () => {
          flowLinkManager.transitionTo({
            name: 'member-invite',
            stepId: 'personal-info',
            queryParams: { role: 'admin' },
          });
        },
      },
      {
        key: 'shortcut-invite-accountant',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.accountant'),
        illustration: 'icon_user_plus_outlined',
        action: () => {
          flowLinkManager.transitionTo({
            name: 'member-invite',
            stepId: 'personal-info',
            queryParams: { role: 'reporting' },
          });
        },
      },
      {
        key: 'shortcut-create-budget',
        isEnabled: true,
        copy: intl.t('qonto-pilot.modal.quick-action.budgets'),
        illustration: 'icon_nav_taxes_and_duties',
        action: () => {
          flowLinkManager.transitionTo({ name: 'create-budget', stepId: 'intro' });
        },
      },
    ],
  };

  let recentKeys = qontoPilotService.getRecentQuickActions();
  let allActions = [
    ...quickActions[intl.t('qonto-pilot.modal.quick-action.group.tasks')],
    ...quickActions[intl.t('qonto-pilot.modal.quick-action.group.shortcuts')],
  ];

  quickActions[intl.t('qonto-pilot.modal.quick-action.group.recent')] = recentKeys
    .map(key => allActions.find(action => action.key === key))
    .filter(Boolean);

  return quickActions;
}

/**
 * To avoid tests having to follow these timings, delays are exported as objects so that their value can be changed in tests
 */
export const QONTO_PILOT_DELAYS = {
  supplierInvoices: 7000,
  searchInputDebounce: 500,
};
