export const OPERATOR = {
  ON: 'on',
  BEFORE: 'before',
  AFTER: 'after',
};

export const FIELD = {
  DUE_DATE: 'due_date',
};

export const DEFAULT_OPERATOR = OPERATOR.ON;
export const DEFAULT_FIELD = FIELD.DUE_DATE;

export const FEATURE_RELEASE_DATE = '2024-09-11';

export const DISCOVERABILITY_STORAGE_KEY = 'automated-reminders-discoverability-viewed';
