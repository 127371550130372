const NRC_STATUS = {
  SCHEDULED: 'scheduled',
  DECLINED: 'declined',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

const NRC_LOGIN_PROMOTION_KEY = 'login-feature-nrc';

export { NRC_STATUS, NRC_LOGIN_PROMOTION_KEY };
